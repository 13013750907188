<template>
    <!-- for accessibility reasons, display button as an <a> if appropriate -->
    <router-link
        v-if="routerLink"
        :class="buttonStyle"
        :to="routerLink"
    >
        <slot name="button-text" />
    </router-link>
    <a
        v-else-if="link && !external"
        :class="buttonStyle"
        :href="link"
        @click="handleClick"
    >
        <slot name="button-text" />
    </a>
    <a
        v-else-if="link && external"
        :class="buttonStyle"
        :href="link"
        target="_blank"
        rel="noopener noreferrer"
        @click="handleClick"
    >
        <slot name="button-text" />
    </a>
    <button
        v-else-if="!link && !routerLink"
        :type="buttonType"
        :class="buttonStyle"
        @click="handleClick"
    >
        <slot name="button-text" />
    </button>
</template>

<script>
export default {
    props: {
        flavour: {
            type: String,
            default: 'standard'
        },
        routerLink: {
            type: Object,
            default: null
        },
        link: {
            type: String,
            default: ''
        },
        buttonType: {
            type: String,
            default: 'button'
        },
        external: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    computed: {
        buttonStyle() {
            // add the button class as default
            let classes = {
                'a-button': true
            };

            // set the background colour
            switch (this.flavour) {
            case 'orange':
                classes['a-button a-button--orange'] = true;
                break;
            case 'white':
                classes['a-button a-button--white'] = true;
                break;
            case 'green':
                classes['a-button a-button--green'] = true;
                break;
            case 'dark-green':
                classes['a-button a-button--dark-green'] = true;
                break;
            case 'secondary-red':
            case 'red':
                classes['a-button a-button--secondary-red'] = true;
                break;
            case 'blue':
                classes['a-button a-button--blue'] = true;
                break;
            case 'dark-blue':
                classes['a-button a-button--dark-blue'] = true;
                break;
            case 'secondary':
                classes['a-button a-button--lightest-grey'] = true;
                break;
            case 'lightest-grey':
                classes['a-button a-button--lightest-grey-full'] = true;
                break;
            case 'lilac':
                classes['a-button a-button--lilac'] = true;
                break;
            case 'medium-grey':
                classes['a-button a-button--medium-grey'] = true;
                break;
            case 'dark-grey':
                classes['a-button a-button--dark-grey'] = true;
                break;
            case 'menu-grey':
                classes['a-button a-button--menu-grey'] = true;
                break;
            case 'darkest-grey':
                classes['a-button a-button--darkest-grey'] = true;
                break;
            case 'magenta':
                classes['a-button a-button--magenta'] = true;
                break;
            case 'mid-teal':
                classes['a-button a-button--mid-teal'] = true;
                break;
            case 'notification-dismiss':
                classes['a-button a-button--notification-dismiss'] = true;
                break;
            case 'nav-white':
                classes['a-button a-button--secondary-nav'] = true;
                break;
            case 'nav-red':
                classes['a-button a-button--secondary-nav selected'] = true;
                break;
            case 'tab':
                classes['a-button a-button--tab'] = true;
                break;
            case 'ghost':
            case 'standard':
            default:
                classes['a-button--transparent'] = true;
            }

            return classes;
        }
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        }
    }
};
</script>

<style scoped>
	.c-button--fixed-width-medium {
		width: 15em;
	}
</style>

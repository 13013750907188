import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        // if we ever need to reset this store please do an overwrite $reset function that preserves the 'appversion' and 'viewportSize' values
        appVersion: APP_VERSION,
        viewportSize: null
    }),

    persist: true
});
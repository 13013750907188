/**
 * Small helper to make the "skip to content" link
 * work with the SPA setup. HTML is in _SkipToLinks.cshtml
 * @param {route} route Vue route object instance
 * @param {router} router Vue router object instance
 */
export function initialiseSkipToLinks(route, router) {
    document
        .querySelector('#skip-to-content')
        ?.addEventListener('click', e => {
            e.preventDefault();
            router.replace({ path: route.path, query: route.query, hash: '#content' });
        });
}